<template>
	<div v-if="isMobile" class="mobile">
		<video class="video" autoplay muted loop playsinline>
			<source src="@/assets/imgs/bg.mp4" type="video/mp4">
			Your browser does not support the video tag.
		</video>

		<div class="overlay">
			<div class="logo">
				<img src="@/assets/imgs/voidge.gif">
			</div>
			<div class="text">
				Experience available on PC
			</div>
		</div>
	</div>
	<div v-if="!isMobile">
		<div class="absolute-0000">
			<canvas v-bind:class="{ 'blur': isFocusOverlayShowing }" id="unity-canvas"></canvas>

			<div v-if="isFocusOverlayShowing" class="absolute-0000 canvas-overlay">
				<div class="flex-center">
					<div class="text-align-center">
						Click anywhere on the screen to<br>
						get back to the action
					</div>
				</div>
			</div>

		</div>

		<div v-if="!webglLoaded" class="absolute-0000 bg-black">
			<div class="flex-center">
				<div class="text-align-center">
					<img width="400" src="@/assets/imgs/voidge.gif">

					<!-- <div class="loading-text">Loading...</div> -->
					<div class="progress-bar">
						<div id="progress-bar-value" class="progress-bar-value"></div>
					</div>
					<h2 class="version">©Voidge | {{ productVersion }}</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { UnityWeb3 } from '@/modules/UnityWeb3'

// Unity integration
var buildUrl = "Build";
var config = {
	dataUrl: buildUrl + process.env.VUE_APP_DATAURL,
	frameworkUrl: buildUrl + process.env.VUE_APP_FRAMEWORKURL,
	codeUrl: buildUrl + process.env.VUE_APP_CODEURL,
	streamingAssetsUrl: process.env.VUE_APP_STREAMINGASSETSURL,
	companyName: process.env.VUE_APP_COMPANYNAME,
	productName: process.env.VUE_APP_PRODUCTNAME,
	productVersion: process.env.VUE_APP_PRODUCTVERSION,
};

export default {
	name: 'App',
	components: {
	},
	data() {
		return {
			unityWeb3: null,

			canvas: null,
			canvasFocus: window.voidgeConfig.isCursorLocked,
			webglLoaded: false,

			isMobile: false,

			unityInstance: null,
		}
	},
	computed: {
		isFocusOverlayShowing() {
			return !this.canvasFocus && this.webglLoaded;
		},
	},
	methods: {
		updateCanvasFocus(focused) {
			if (!this.webglLoaded) return; // Certifique-se de que o Unity está carregado

			this.canvasFocus = focused;

			try {
				if (this.unityInstance) {
					this.unityInstance.SendMessage(window.voidgeConfig.proxyGameObject, 'ChangePointerState', this.canvasFocus ? 1 : 0);
				}
			} catch (error) {
				console.error('Erro ao enviar mensagem para Unity:', error);
			}
		}
	},
	created() {
		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	},
	mounted() {
		if (this.isMobile == false) {
			this.canvas = document.getElementById("unity-canvas");


			window.addEventListener('focus', () => {
				this.updateCanvasFocus(true)
			});

			window.addEventListener('blur', () => {
				this.updateCanvasFocus(false)
			});

			let progressBarValue = document.getElementById("progress-bar-value");

			createUnityInstance(this.canvas, config, (progress) => {
				progressBarValue.style.width = progress * 100 + "%";
			}).then(unityInstance => {
				window.unityInstance = unityInstance;
				this.unityInstance = unityInstance;

				this.unityWeb3 = new UnityWeb3(unityInstance)

				this.unityWeb3.watchGameState(() => {
					this.webglLoaded = true;
				})
			});
		}
	}

}
</script>

<style>
@font-face {
	font-family: 'Nexa';
	src: url('assets/fonts/Nexa_Light.otf') format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Nexa';
	src: url('assets/fonts/Nexa_Bold.otf') format("opentype");
	font-weight: bold;
	font-style: normal;
}

* {
	padding: 0;
	margin: 0;
	border: 0;
}

body {
	font-size: 0;
}

canvas {
	background-color: black;
	width: 100%;
	height: 100%;
}

.blur {
	filter: blur(1px);
}

.absolute-0000 {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

.canvas-overlay {
	background-color: black;
	backdrop-filter: blur(6px);
	opacity: 0.5;
	pointer-events: none;

	font-family: 'Nexa';
	font-weight: bold;
	color: white;
	font-size: 2rem;
}

.canvas-overlay * {
	pointer-events: none;
	user-select: none;
}

.flex-center {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.text-align-center {
	text-align: center;
}

.loading-text {
	margin-bottom: 10px;
	text-transform: lowercase;

	font-size: 1rem;
	font-family: 'Nexa';
	color: white;
}

.progress-bar {
	margin-top: 40px;
	width: 400px;
	height: 6px;
	background-color: #231f20;
	border-radius: 8px;
}

.progress-bar-value {
	background-image: linear-gradient(to right, white, blueviolet);
	width: 0%;
	height: 100%;
	border-radius: 8px;
}


.mobile .overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.mobile .overlay .logo {
	text-align: center;
	padding: 0 20px;
}

.mobile .overlay .text {
	font-size: 1.25rem;
	color: white;
	padding: 0 40px;
	font-family: "Nexa";
	font-weight: bold;
	margin-top: 50px;
}

.mobile .overlay .logo img {
	width: 100%;
	max-width: 480px;
}

.video {
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

.version {
	font-family: 'Nexa';
	font-weight: 'semibold';
	color: rgba(128, 128, 128, 0.767);
	font-size: 1rem;
	padding-bottom: 10px;
	padding-right: 15px;
	position: fixed;
	/* Make the position of the element fixed */
	bottom: 0;
	/* Align it to the bottom */
	right: 0;
	/* Align it to the left */
}
</style>
